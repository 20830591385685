/* Adjust the .unique-chat-container to align with the base styling */
.unique-chat-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers children horizontally */
    justify-content: flex-start; /* Adjust this to avoid stretching */
    height: calc(100vh - 50px); /* Account for chat form */
    width: 100%;
    margin: auto;
    border: none;
    border-radius: 20px 20px 0 0; /* Rounded corners at the top only */
    background: var(--background-primary); /* Adjusted to use base variable */
    box-shadow: var(--shadow); /* Use base shadow style */
    overflow: hidden; /* Prevents overflow */
}

/* Modern Styles for Individual Messages adjusted to use variables */
.unique-li {
    margin-bottom: 12px;
    padding: 14px;
    border-radius: 15px;
    background-color: var(--background-primary); /* Adjusted to use base variable */
    box-shadow: var(--shadow); /* Use base shadow style */
}


.unique-chat-form {
    position: fixed; 
    bottom: 0; 
    left: 50%; /* Centers the form */
    transform: translateX(-50%); /* Centers the form */
    display: flex;
    padding: 18px;
    background: var(--background-secondary); 
    border-top: none;
    border-radius: 0; 
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    min-width: 50%; /* Set minimum width to the form */
}

.unique-chat-form input[type="text"] {
    flex: 1;
    padding: 12px;
    margin-right: 12px;
    border: 2px solid var(--border-color); 
    background-color: var(--background-primary); 
    border-radius: 15px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
}

.unique-chat-form button:disabled {
  background-color: #cccccc; /* Light grey background */
  color: #666666; /* Darker grey text */
  cursor: not-allowed; /* Change cursor to indicate it's not allowed to click */
}

.unique-chat-form button {
  /* Your existing button styles */
  background-color: #214d63; /* Example primary color */
  color: white; /* Text color */
  /* Add more styles as needed */
}

.unique-chat-form button:hover:not(:disabled) {
  background-color: #4691c0; /* Darker shade for hover, only when not disabled */
}


.unique-chat-form button:hover {
    background-color: darken(var(--accent-color), 10%); /* Darken on hover */
}

.user-label {
    color: var(--accent-color);
  }
  
  .DALLE-label {
    color: darken(var(--accent-color), 20%);
  }
  hljs {
    background: var(--background-secondary); /* Slightly different background for code blocks */
    padding: 1em;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    overflow-x: auto; /* Ensure wide code blocks are scrollable */
}

/* Adjust the styles for user and AI labels */
.unique-user-label, .unique-DALLE-label {
    display: block; /* Make labels block-level for better spacing */
    margin-bottom: 0.5em; /* Space between label and message */
}

.unique-user-label {
    color: var(--accent-color); /* Use the accent color for user labels */
    font-weight: bold; /* Make label text bold */
}

.unique-DALLE-label {
    color: darken(var(--accent-color), 20%); /* Darkened accent color for AI labels */
    font-weight: bold; /* Make label text bold */
}

/* Ensure that loading dots animation is visible and matches the accent color */
@keyframes blink {
    0%, 49% { opacity: 0; }
    50%, 100% { opacity: 1; }
}

.unique-loading-dots span {
    animation: blink 1.4s linear infinite;
    padding: 0 2px;
}

/* Adjusted styles for input and button hover to use CSS variables */
.unique-chat-form input[type="text"], .unique-chat-form button {
    transition: background-color 0.3s, border-color 0.3s;
}

.unique-chat-form button:hover {
    background-color: darken(var(--accent-color), 10%); /* Adjust hover effect */
    /* Note: CSS alone does not support the darken function; consider a preprocessor or manually specify the color */
}

/* Consideration for image sizes within messages */
.unique-messages-box img {
    max-width: 100%; /* Ensure images do not overflow the message box */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Consistent rounding with other elements */
    
}

/* Style adjustments for links within messages for visibility and consistency */
.unique-messages-box a {
    color: var(--accent-color);
    text-decoration: underline; /* Ensure links are recognizable */
}

.unique-messages-box {
    overflow-y: auto;
    width: 100%; /* Adjust width as needed to not span the full width */
    max-width: 1000px; /* Set a max-width to avoid overly wide messages box */
    padding: 24px;
    margin: 0 auto; /* Center the messages box within the container */
    background-color: var(--background-secondary);
    padding-bottom: 100px; /* Adjust the value to match or exceed the height of your chat form */
}
  
/* Individual messages are likely block elements, so text-align can center the text */
.unique-li {
    margin-bottom: 12px;
    padding: 14px;
    border-radius: 15px;
    background-color: var(--background-primary);
    box-shadow: var(--shadow);
    text-align: center; /* Center text within each message */
}
  .unique-img {
    max-width: 90%;
    height: auto;
    border-radius: 12px;
    display: block; /* Ensure images are block level to respect max-width */
    margin: 0 auto; /* Center images within the message */
  }
  
  /* Improved visibility for "Image is generating" text */
  .unique-loading-dots {
    display: inline-block; /* Makes the container inline */
    margin-left: 5px; /* Adds a small space between the text and the dots */
  }