:root {
  --bg-color: #343a40;
  --text-color: #ffffff;
  --hover-bg-color: #007bff;
  --active-bg-color: #0056b3;
  --active-border-color: #004085;
  --font-family: 'Roboto', sans-serif;
  --border-radius: 5px;
  --transition-speed: 0.3s;
}


body {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
nav {
  background-color: var(--bg-color);
  padding: 20px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navLinks, nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

nav ul li {
  margin: 0 20px;
}

nav ul li a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 16px;
  padding: 10px 15px;
  border-radius: var(--border-radius);
  transition: background-color var(--transition-speed);
}

nav ul li a:hover, nav ul li a:focus {
  background-color: var(--hover-bg-color);
  color: var(--text-color);
}

nav ul li a.active {
  background-color: var(--active-bg-color);
  color: var(--text-color);
  border: 1px solid var(--active-border-color);
}


.logout-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: var(--text-color);
  font-size: 14px;
  padding: 8px 12px;
  cursor: pointer;
  transition: color var(--transition-speed);
}

.logout-btn:hover {
  color: #b3b3b3;
}


.body-wrapper {
  display: grid;
  grid-template-columns: 1fr min(100%, 75rem) 1fr;
}

.body-content {
  grid-column: 2;
}
