/* Base styling for the chat interface with updated colors */
:root {
  --background-primary: #ffffff; /* Primary background color */
  --background-secondary: #f7f7f7; /* Secondary background for input area and messages */
  --accent-color: #4a76a8; /* Accent color for buttons and links */
  --text-primary: #333333; /* Primary text color */
  --text-secondary: #555555; /* Secondary text color for less emphasis */
  --border-color: #e0e0e0; /* Border color for inputs and messages box */
  --shadow: 0 2px 10px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  --button-disabled-bg: #cccccc; /* Button disabled background color */
  --button-disabled-text: #666666; /* Button disabled text color */
}


  @keyframes blink {
    0%, 49% { opacity: 0; }
    50%, 100% { opacity: 1; }
  }
  
  .loading-dots {
    display: inline-block; /* Makes the container inline */
    margin-left: 5px; /* Adds a small space between the text and the dots */
  }
  
  .loading-dots span {
    display: inline-block; /* Ensures dots are inline */
    animation-name: blink;
    animation-duration: 1s; /* Making the animation a bit faster */
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-timing-function: steps(1, end); /* Makes the change abrupt */
  }
  
  .loading-dots span:nth-child(1) { animation-delay: 0.2s; }
  .loading-dots span:nth-child(2) { animation-delay: 0.4s; }
  .loading-dots span:nth-child(3) { animation-delay: 0.6s; }
  
  .chat-form {
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap: 10px;
    /* add font size for the text in chat-form */
    font-size: 18px; /* Adjust the pixel value depending on your requirement */
}

.chat-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    /* add font size for the text in chat-input, if different from chat-form */
    font-size: 16px; /* Adjust the pixel value depending on your requirement */
}
  
  .chat-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
    max-height: 100vh;
    width: 100%; /* Ensures the container width is responsive */
    max-width: 800px; /* Limits the maximum width of the chat container */
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--background-primary);
    border-radius: 12px; /* Smooth rounded corners for the container */
    box-shadow: var(--shadow);
    margin: 0 auto; /* Centers the container horizontally */
  }
  
  .messages-box {
    overflow-y: auto;
    margin-bottom: 20px;
    flex-grow: 1;
    padding: 15px;
    background-color: var(--background-secondary);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.03);
  }
  
  .chat-form {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 10px 15px;
    background-color: var(--background-secondary);
    border-radius: 8px;
    border: 1px solid var(--border-color);
    box-shadow: var(--shadow);
  }
  
  input, button {
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  input {
    flex-grow: 1;
    color: var(--text-primary);
  }

/* Update button styles to match the theme in imagegen.css */
button {
  background-color: var(--accent-color); /* Use accent color for button background */
  color: white; /* Text color for button */
  border: none; /* Remove border */
  padding: 10px 15px; /* Padding for button */
  border-radius: 4px; /* Border radius for button */
  cursor: pointer; /* Cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

/* Adjust button:hover to use CSS custom property */
button:hover {
  background-color: darken(var(--accent-color), 10%); /* Darken button on hover */
}

/* Apply disabled styles using CSS custom properties */
button:disabled {
  background-color: var(--button-disabled-bg); /* Disabled background color */
  color: var(--button-disabled-text); /* Disabled text color */
  cursor: not-allowed; /* Cursor to indicate disabled state */
}

/* CSS custom property adjustments for hover effects */
button:hover:not(:disabled) {
  background-color: #4691c0; /* Darker shade for hover, only when not disabled */
}
  .messages-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .messages-box li {
    margin-bottom: 12px;
    padding: 8px;
    background-color: #FFF;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    box-shadow: var(--shadow);
  }
  
  .messages-box li:last-child {
    margin-bottom: 0;
  }
  
  /* Specific user and AI message styling */
  .user-message {
    color: var(--text-primary);
    background-color: var(--background-primary);
  }
  
  .ai-message {
    color: var(--text-secondary);
    background-color: var(--background-secondary);
  }
  
  
  .user-label {
    color: var(--accent-color);
  }
  
  .ai-label {
    color: darken(var(--accent-color), 20%);
  }
  