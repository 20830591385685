/* Login.css updated with unique identifiers and consistent color scheme */
#loginContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh; /* Use viewport height to fill the screen */
  background-color: #f4f7f6;
  padding-top: 5%; /* Adjust this value to get your desired position */
}

#loginForm {
  background: #ffffff;
  max-width: 400px;
  margin: 20px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#loginForm h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

#loginForm label {
  display: block;
  margin: 10px 0;
}

#loginForm input[type="text"],
#loginForm input[type="email"],
#loginForm input[type="password"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Include padding and border in element's width and height */
}

#loginForm input[type="text"]:focus,
#loginForm input[type="password"]:focus,
#loginForm input[type="email"]:focus {
  border-color: #5ab9ea;
  outline: none; /* Removes the default focus outline */
}

#loginForm button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #5ab9ea; /* Primary color */
  color: white;
  cursor: pointer;
}

#loginForm button[type="submit"]:hover:not(:disabled) {
  background-color: #4691c0; /* Darker shade for hover, only when not disabled */
}

#loginForm button[type="submit"]:disabled {
  background-color: #cccccc; /* Light grey background */
  color: #666666; /* Darker grey text */
  cursor: not-allowed; /* Change cursor to indicate it's not allowed to click */
}

/* Responsive adjustments */
@media (max-width: 420px) {
  #loginForm {
    width: 90%;
    padding: 20px;
  }
}
